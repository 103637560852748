import { useState } from "react";
import { Grid } from "@mui/material"
import SubwaySelector from "../components/SubwaySelector";
import PrizesSetup from "./PrizesSetup";

const Lottery = () => {

    const [prizes,setPrizes] = useState([]);

    return (
        <Grid container spacing={2}
                direction="row"
                justifyContent="center"
                alignItems="center">
            <Grid item>
                <PrizesSetup 
                    prizes={prizes} 
                    setPrizes={setPrizes}/>
            </Grid>
            <Grid item xs={12} sm={8}>
                <SubwaySelector 
                    choices={prizes} 
                    max_choices={1}/>
            </Grid>
        </Grid>
    );
}

export default Lottery;