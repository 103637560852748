import { Stack, Grid } from "@mui/material";
import Box from '@mui/material/Box';
import { ThemeProvider, createTheme } from '@mui/material/styles';
//import { Container } from "@mui/material";
import Prize from "./Prize";
import { useCallback} from "react";

const PrizeSet = ({data,setData}) => {
    const handleDelete = useCallback((value) => {
        setData(data.filter(val => val.id !== value.id));
    }, [data,setData]);

    const prizes = data.map((prize) => {
        return (
            <Prize prize={prize} 
                    handleDelete={handleDelete} 
                    key={prize.id}/>
        )
    });

    const theme = createTheme({
        components: {
            MuiStack: {
                defaultProps: {
                    useFlexGap: true,
                    flexWrap: "wrap",
                    alignContent: "center"
                },
            },
            Box: {
                alignContent: "center"
            }
        },
    });

    return (
        <Grid item xs={3}>
            <ThemeProvider theme={theme}>
                <Box sx={{width: 200}}>
                    <Stack spacing={{ xs: 1, sm: 2 }} direction="row">{prizes}</Stack>
                </Box>
            </ThemeProvider>
        </Grid>
    );
}
//        <Stack alignContent={"center"} width="3" spacing={1} direction="row">{prizes}</Stack>
//<Container style={{maxWidth:200}} maxWidth={false}>{prizes}</Container>

export default PrizeSet;