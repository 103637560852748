import { Button, Grid, TextField } from "@mui/material";
import { useState, useCallback } from "react";
import { v4 as uuid } from 'uuid';

const AddPrize = ({addPrize}) => {
    const [text, setText] = useState('');

    const updateText = useCallback((event) => {
        setText(event.target.value || '');
    }, [setText]);

    const savePrize = useCallback(() => {
        addPrize({
            id: uuid(),
            name: text,
            count: 1
        });
        setText('');
    }, [addPrize, text]);

    return (
        <Grid item sm={20}>
            <TextField 
                placeholder="set prize"
                value={text}
                inputProps={{ maxLength: 10 }}
                onChange={updateText}
                onKeyUp={(ev) => {
                    if(ev.key === "Enter") {
                        savePrize();
                    }
                }}
            />
            <Button 
                onClick={savePrize}
            >Add</Button>
        </Grid>
    );
}

export default AddPrize;