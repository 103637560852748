import { useCallback } from "react";
import AddPrize from "../components/AddPrize";
import PrizeSet from "../components/PrizeSet";

const PrizesSetup = ({  prizes,
                        setPrizes,
                        setMaxPrizes,
                        flag_new,
                        setFlagNew}) => {
    if(flag_new) {
        setPrizes([]);
        setMaxPrizes(0);
        setFlagNew(false);
    }
    const addPrize = useCallback((newPrize) => {
        for(var i=0; i < newPrize.count; ++i) {
            setPrizes([...prizes,{id:newPrize.id,
                                    name:newPrize.name}]);
        }
        // not sure why prizes, prizes is NOT set at this 
        // point, so need to reproduce what prizes is being
        // set to, rather than using prizes.length as I would
        // obviously prefer.  Must have something to do with
        // rendering
        setMaxPrizes([...prizes,{id:newPrize.id,
            name:newPrize.name}].length);
    },[prizes,setPrizes,setMaxPrizes]);

    return (
        <>
            <AddPrize   addPrize={addPrize}/>
            <PrizeSet   data={prizes} 
                        setData={setPrizes}/>
        </>
    );
}

export default PrizesSetup;