//import { Button, Container } from "@mui/material";
import { Button, Grid } from "@mui/material";
import PrizesSetup from "./PrizesSetup";
import { useCallback } from "react";
//import { useState } from "react";
import { Link } from "react-router-dom";
//import NumberInput from "../components/NumberInput";
//import { Unstable_NumberInput as NumberInput } from '@mui/base/Unstable_NumberInput';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';


// No non-hacky way to pass state between pages-- use redux
// not sure what error at the end is about.

const About = ({    prizes,
                    setPrizes,
                    maxPrizes,
                    setMaxPrizes,
                    flag_new,
                    setFlagNew,
                    setFlagAbout}) => {
    setFlagAbout(true);
    const saveMaxPrizes = useCallback((max_prizes) => {
        setMaxPrizes(max_prizes);
    }, [setMaxPrizes]);
    const renderedPrizes = prizes.map((prize,i) => {
        return (
            <option value={i+1} key={prize.id}>{i+1}</option>
        )
    });
    return (
        <Grid
            container
            spacing={2}
            direction="column"
            alignItems="center"
            justifyContent="center" 
            maxWidth="100%">
            <PrizesSetup 
                prizes={prizes}
                setPrizes={setPrizes}
                setMaxPrizes={setMaxPrizes}
                flag_new={flag_new}
                setFlagNew={setFlagNew}/>
            <Grid item xs={3}>
                <FormControl style={{minWidth: 200}}>
                    <InputLabel 
                        variant="standard" 
                        htmlFor="uncontrolled-native"
                    >Number of Prizes</InputLabel>
                    <NativeSelect
                        inputProps={{
                            name: 'prize_count',
                            id: 'uncontrolled-native',
                        }}
                        value={maxPrizes}
                        onChange={(event) => {
                            saveMaxPrizes(event.target.value);}}
                    >{renderedPrizes}</NativeSelect>
                </FormControl>
                <Button LinkComponent={Link} to="/subway"
                >Play</Button>
            </Grid>
        </Grid>
    );
}
//<Container maxWidth="sm">
//</Container>
export default About;