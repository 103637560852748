import SubwaySelector from "../components/SubwaySelector";

const Magic = () => {
    return (
        <div>
            <SubwaySelector 
                choices={["Certain",
                            "No doubt",
                            "Rely on it",
                            "Definitely",
                            "Decidedly",
                            "Likely",
                            "Yes",
                            "Hazy",
                            "Not now",
                            "Later",
                            "Looks bad",
                            "Doubtful",
                            "No"]}/>
        </div>
    );
}

export default Magic;