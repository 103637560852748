import SubwaySelector from "../components/SubwaySelector";

const Subway = ({   flag_reset, 
                    setFlagReset,
                    choices,
                    max_choices,
                    setFlagAbout}) => {
    setFlagAbout(false);
    return (
        <SubwaySelector
            flag_reset={flag_reset}
            setFlagReset={setFlagReset}            
            choices={choices} 
            max_choices={max_choices}/>
    );
};

export default Subway;