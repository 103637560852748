//import logo from './logo.svg';
import './App.css';
import { BrowserRouter } from 'react-router-dom'; 
import Router from './pages/Router'
import AppHeader from './components/AppHeader';
import { useState } from 'react';

function App() {
  const [flag_new,setFlagNew] = useState(false);
  const [flag_reset,setFlagReset] = useState(false);
  const [flag_about,setFlagAbout] = useState(false);

  return (
    <BrowserRouter >
      <div className="App">
        <AppHeader
          setFlagNew={setFlagNew}
          setFlagReset={setFlagReset}
          flag_about={flag_about} />
        <Router       
          flag_reset={flag_reset}
          setFlagReset={setFlagReset}
          flag_new={flag_new}
          setFlagNew={setFlagNew}
          setFlagAbout={setFlagAbout}/>
      </div>
    </BrowserRouter>
  );
}

export default App;
