//import { Button, Container } from '@mui/material';
import * as d3 from 'd3';
import { useEffect, useRef, useState } from "react";

/*
=====================================================
Settings
=====================================================
*/
let BUTTON_RADIUS = 15;
let BUTTON_RADIUS_BIG = 23; // ~ 1.5 times
let MARGIN = {    
    LEFT: 50,
    RIGHT: 50, 
    TOP: 50, 
    BOTTOM: 50
};
if(isMobile()) {
    BUTTON_RADIUS = 40;
    BUTTON_RADIUS_BIG = 60;  // ~ 1.5 times
    MARGIN.TOP = BUTTON_RADIUS_BIG * 2 + 5;
}
const INIT_CONNECTIONS = {MIN: 3, MAX: 10};
const FONT_SIZE = { NORMAL: 16, NORMAL_M: 32, 
                    LARGE: 32, LARGE_M: 64};
// tokyo subway colors, not sure if all valid
// silver = grey
// skyblue = steelblue
// gold = darkgoldenrod
// emerald = teal (mediumaquamarine too light)
// rose = tomato
// leaf = darkseagreen
const RESULT_COLORS = ["orange","red","grey","steelblue",
                        "green","darkgoldenrod","purple",
                        "teal","brown","tomato",
                        "blue","darkseagreen","magenta",
                        "darkorange","darkred","darkgrey",
                        "midnightblue",
                        "darkgreen","gold","darkviolet",
                        "seagreen","chocolate","salmon",
                        "darkblue","seagreen","darkmagenta"
                        ];
/*
STATES:
ANIMATING    - line currently animating
HIGHLIGHTED  - completed and highlighted with expanded text 
COMPLETED    - completed, but not highlighted
UNEXPLORED   - path not clicked yet
*/
const STATES = {
    ANIMATING: "ANIMATING",
    HIGHLIGHTED: "HIGHLIGHTED",
    COMPLETED: "COMPLETED",
    UNEXPLORED: "UNEXPLORED"
};
// const DEFAULT_CHOICES = [{id:"Ikura",name:"Ikura"},
//     {id:"Green Tea",name:"Green Tea"},
//     {id:"Kings",name:"Kings"},
//     {id:"Alice",name:"Alice"},{id:"Summer",name:"Summer"},
//     {id:"Weeds",name:"Weeds"},{id:"Sunrise",name:"Sunrise"},
//     {id:"Sunset",name:"Sunset"},{id:"Wings",name:"Wings"},
//     {id:"Pather",name:"Pather"},{id:"Samurai",name:"Samurai"},
//     {id:"Woman",name:"Woman"},{id:"Storm",name:"Storm"}];

const DEFAULT_CHOICES = [{id:"a",name:"a"},{id:"b",name:"b"},
    {id:"c",name:"c"},{id:"d",name:"d"},{id:"e",name:"e"},
    {id:"f",name:"f"},{id:"g",name:"g"},{id:"h",name:"h"},
    {id:"i",name:"i"},{id:"j",name:"j"},{id:"k",name:"k"},
    {id:"l",name:"l"},{id:"m",name:"m"},{id:"n",name:"n"},
    {id:"o",name:"o"},{id:"p",name:"p"},{id:"q",name:"q"},
    {id:"r",name:"r"},{id:"s",name:"s"},{id:"t",name:"t"},
    {id:"u",name:"u"},{id:"v",name:"v"},{id:"w",name:"w"},
    {id:"x",name:"x"},{id:"y",name:"y"},{id:"z",name:"z"}];
/*
=====================================================
Data
=====================================================
*/
// data:
//   connecting lines between the lines, both specified
//     by user and automatically generated
//     need a better name!!!
//   col = vertical line # (0-based)
//   row = discrete horizontal point 
//         (each line has n distinct points)
//   [col][row].next = {col,row}
//         null or object pointing to position
//         in this data set
//   [col][row].x = x pos of point
//   [col][row].y = y pos of connector 

// results:
//   stores the path starting from each point. As best I 
//     can tell, currently not using it beyond populating 
//     it.
//   col = vertical line # (0-based)
/*
=====================================================
Utilities
=====================================================
*/

/*
===============================================================
// canToggleHighlight(results,col) 
//   checks col in results to see if highlight can be 
//   toggled to either on (from off) or off (from on)
===============================================================
*/
function font_Normal() {
    return isMobile() ? FONT_SIZE.NORMAL_M : FONT_SIZE.NORMAL;
}

function font_Large() {
    return isMobile() ? FONT_SIZE.LARGE_M : FONT_SIZE.LARGE;
}
/*
===============================================================
// canToggleHighlight(results,col) 
//   checks col in results to see if highlight can be 
//   toggled to either on (from off) or off (from on)
===============================================================
*/
function canToggleHighlight(results,col) {
    return animationCount(results) === 0
        && (results.current[col].state === STATES.COMPLETED
            || results.current[col].state 
                === STATES.HIGHLIGHTED);
}

/*
===============================================================
// isClickable(results, col, max_choices)
//   checks col in results to see if there is a valid
//   action available, that is, can the button can be 
//   clicked to run animation or display results (actually,
//   not currently an option, but I may change)
===============================================================
*/
function isClickable(results, col, max_choices) {
    // clickable if there is no active animation
    // and either the max choices have not all been made
    //     or col highlight can be toggled
    return ( animationCount(results) === 0
            && (resultCount(results) < max_choices
                || canToggleHighlight(results,col)));
}

/*
===============================================================
// canExpand(c)
//   checks circle, c to see if radius can be expanded to 
//   big
===============================================================
*/
function canExpand(c) {
    return parseInt(c.attr("r")) === BUTTON_RADIUS;
}

/*
===============================================================
// canContract(c)
//   checks circle, c's radius to see if it can 
//   be shrunk to original size
===============================================================
*/
function canContract(c) {
    return parseInt(c.attr("r")) === BUTTON_RADIUS_BIG;
}


/*
===============================================================
// isMobile() 
//   returns true if on mobile device, false otherwise
===============================================================
*/
function isMobile() {
    return ('ontouchstart' in window 
        || navigator.maxTouchPoints > 0);
}

/*
===============================================================
// getColor(i) 
//   returns color for given index, i
===============================================================
*/
function getColor(i) {
    if(i < 0) return "black";
    return RESULT_COLORS[i%RESULT_COLORS.length]
}

/*
===============================================================
// getRandomInt(min=INIT_CONNECTIONS.MIN, 
]//              max=INIT_CONNECTIONS.MAX) 
//   returns a number between min and max, inclusive
===============================================================
*/
function getRandomInt(min=INIT_CONNECTIONS.MIN, 
                        max=INIT_CONNECTIONS.MAX) {
    var ret = min+Math.floor(Math.random() * (max-min+1));
    return ret;
}

/*
===============================================================
// shuffleArray(array)
//   rearranges array into random order
===============================================================
*/
function shuffleArray(array) {
    for (var i = array.length - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var temp = array[i];
        array[i] = array[j];
        array[j] = temp;
    }
}

/*
===============================================================
// resultCount(results)
//   returns count of results, that is the number choices 
//     selected so far.
===============================================================
*/
function resultCount(results) {
    return results.current.reduce((total, x) => 
        ((x !== null && x.state !== STATES.UNEXPLORED) 
            ? total + 1 : total), 0)
}

/*
===============================================================
// animationCount(results)
//   returns count of results currently being animated.
===============================================================
*/
function animationCount(results) {
    return results.current.reduce((total, x) => 
        ((x !== null && x.state === STATES.ANIMATING) 
            ? total + 1 : total), 0)
}

/*
===============================================================
// refreshClickPoints(svg, scale_x, scale_y, n_points,
//                     data, results, c1)
//   remove existing click points from the lines and re-add 
//     them so that they are on top.
//   click point locations are stored in the data data 
//     structure
===============================================================
*/
function refreshClickPoints(svg, scale_x, scale_y, n_points,
                            data, results, c1) {
    svg.selectAll(".clickpoint").remove();
    for(var col=0; col<data.current.length; ++col) {
        for(var row=0; row<data.current[col].length; ++row){
            if( data.current[col][row].next === null) {
                svg.append("circle")
                .attr("cx",data.current[col][row].x)
                .attr("cy",data.current[col][row].y)
                .attr("r",5)
                .attr("class","clickpoint")
                .attr("pointer-events","painted")
                .attr("visibility","hidden")
                .call(installPointHandlers, 
                        svg, 
                        scale_x, scale_y,
                        n_points,
                        data,
                        //data, setData,
                        results,
                        c1);
            } 
        }
    }
}

/*
===============================================================
// buildPath(svg, column, rect_pos_y, data)
//   build a path string for the specified column using the
//     the current data ref 
//   returns final column and path string
===============================================================
*/
function buildPath(svg, column, rect_pos_y, data) {
    var col = column;
    var row = 0;
    var path = "M" + data.current[col][row].x + " " + MARGIN.TOP;

    while(row < data.current[col].length) {
        while(row < data.current[col].length &&
            data.current[col][row].next === null ) {
            ++row;
        }
        if(row === data.current[col].length) break;

        path += " L" + data.current[col][row].x 
                + " " + data.current[col][row].y;
        var col1 = col;
        col = data.current[col1][row].next.col;
        row = data.current[col1][row].next.row;
        path += " L" + data.current[col][row].x 
                + " " + data.current[col][row].y;
        ++row; // avoid bouncing back to previous column
    }
    --row;
    path += " L" + data.current[col][row].x 
                + " " + rect_pos_y;
    
    return [col,path];
}

/*
===============================================================
// animateLine(svg, pathstr, color, pathclass, end, 
//             results)
//   draws the specified path/color as animation
//   borrowed from observable and modified to suit my needs
===============================================================
*/
function animateLine(svg, pathstr, color, startcol, 
                    pathclass, pathclassoff, end,
                    results) {
    if( results.current[startcol].state !== STATES.UNEXPLORED ) {
        return;
    }

    results.current[startcol].state = STATES.ANIMATING;
    // Add the line
    var path = svg.append("path")
        .attr("d", pathstr)
        .attr("class",pathclass)
        .attr("stroke", color);
    
    // Get the length of the path, which we will use for the intial offset to "hide"
    // the graph
    const length = path.node().getTotalLength();
    
    // This function will animate the path over and over again
    function repeat() {
        // Animate the path by setting the initial offset and dasharray and then transition the offset to 0
        path.attr("stroke-dasharray", length + " " + length)
            .attr("stroke-dashoffset", length)
            .transition()
            .ease(d3.easeLinear)
            .attr("stroke-dashoffset", 0)
            .duration(10000)
            .on("end", () => {
                end();
                path.classed(pathclass,false);
                path.classed(pathclassoff,true);
            });
    };
    
    // Animate the graph for the first time
    repeat();
}

/*
===============================================================
// getBounds(col, row, n_points, data) 
//   defines the upper/lower bounds of valid click points
//   adjacent to current row/column point. 
//   Assumes data[col][row].next is null so does not check
//   for that condition.
//   Uses current data ref
===============================================================
*/
function getBounds(col, row, n_points, data) {
    var ret = {left_lower:-1,left_upper:n_points,
                right_lower:-1,right_upper:n_points};
    // up
    for(var i=row-1; 
        i>=0 
            && (ret.left_lower === -1
            || ret.right_lower === -1); 
        --i) {
            if(data.current[col][i].next != null) {
            if(ret.left_lower === -1 
                && data.current[col][i].next.col < col) {
                ret.left_lower = data.current[col][i].next.row;
            } else if(ret.right_lower === -1
                && data.current[col][i].next.col > col){
                ret.right_lower = data.current[col][i].next.row;
            }
        }
    }

    // down
    for(i=row+1; i<n_points; ++i) {
        if(data.current[col][i].next != null) {
            if(ret.left_upper === n_points 
                    && data.current[col][i].next.col < col) {
                ret.left_upper = data.current[col][i].next.row;
            } else if(ret.right_upper === n_points
                    && data.current[col][i].next.col > col){
                ret.right_upper = data.current[col][i].next.row;
            }
            if(ret.left_upper<n_points && ret.right_upper<n_points) break;
        }
    }
    return ret;
}

/*
===============================================================
// getLinePath(c1,c2)
// create straight path from c1 to c2
===============================================================
*/
function getLinePath(c1,c2) {
    var x1 = parseInt(c1.attr("cx"));
    var x2 = parseInt(c2.attr("cx"));
    var offset1 = 0;
    return "M" + (x1 + offset1) + " " + c1.attr("cy") 
        + " L" + (x2 - offset1) + " " + c2.attr("cy");
}

/*
===============================================================
// highlighted(startcol)
//   returns true if current column is highlighted,
//     false otherwise.. 
===============================================================
*/
function highlighted(startcol) {
    return (d3.selectAll(".selectedpath[stroke='"
            + getColor(startcol)
            + "']").classed("highlightedpath"));
}

/*
===============================================================
// completedPathClicked(svg,startcol)
//   if specified column already highlighted,
//      remove highlight and dehighlight classes
//   otherwise highlights specified path
//      and dehighlights everything everything else
===============================================================
*/
function completedPathClicked(svg,startcol,results) {
    if( !canToggleHighlight(results,startcol) ) {
        return;
    } 

    let endcol = results.current[startcol].endcol;

    let h = highlighted(startcol);
    
    // remove ALL highlightedpath classes from selectedpaths
    //   (i.e., paths that have been clicked)
    // AND
    // IF clicked path was already highlighted:
    //    REMOVE dehighlightedpath from ALL selectedpaths
    // ELSE (clicked path was NOT already highlighted)
    //    ADD dehighlightedpath to ALL selectedpaths
    d3.selectAll(".selectedpath,.verticalpath,.connectpath")
                .classed("dehighlightedpath",!h)
                .classed("highlightedpath",false);

    // remove and append path of interest (to make top-most)
    // adjust stroke width
    if(!h) {
        // clicked path NOT already highlighted, so highlight it
        // remove the path that was clicked
        let path = d3.selectAll(".selectedpath[stroke='"
                + getColor(startcol)
                + "']").remove();
        // re-add the path that was clicked to make top z-order
        svg.append("path")
            .attr("d", path.attr("d"))
            .attr("class",path.attr("class"))
            .attr("fill",path.attr("fill"))
            .attr("stroke", path.attr("stroke"))
            .attr("font-size",font_Large())
            .attr("visibility","visible")
            .classed("highlightedpath",true)
            .classed("dehighlightedpath",false);
        // selecting, so set current to highlighted, rest dehighlighted
        svg.selectAll(".hideable")                  // hide everyone
            .attr("visibility","hidden");
        svg.select(".textchoice[font-size='" + font_Large() + "']") // adjust font for previous highlight
            .attr("font-size",font_Normal());
        svg.selectAll("#textchoice"+endcol)        // highlight choice
            .attr("font-size",font_Large())
            .attr("visibility","visible");
    } else {
        // deselecting, so remove highlight classes
        svg.selectAll(".hideable")
            .attr("visibility","visible");
        // and return selected classes font to normal size
        svg.select("#textchoice"+endcol)
            .attr("font-size",font_Normal());
    }
}

function mouseEnterCircle(c, svg, results, startcol, max_choices) {
    if( isClickable(results, startcol, max_choices) ) {
        if(canExpand(c)) {
            c.attr("r",BUTTON_RADIUS_BIG);
        }
        if( canToggleHighlight(results, startcol) ) {
            completedPathClicked(svg,startcol,results);
        }
    }
}

function mouseLeaveCircle(c, svg, results, startcol, max_choices) {
    if( canContract(c) ) {
        c.attr("r",BUTTON_RADIUS);
    }
    if( canToggleHighlight(results, startcol) ) {
        completedPathClicked(svg,startcol,results);
    }
}

/*
===============================================================
// installStartButtonHandlers(c, svg, startcol, max_choices, 
//                              rect_pos_y,
//                              data, results)
//   defines handler for clicking on start buttons at the
//   top.  Defines click event handler for c. 
===============================================================
*/
// TODO: create a rect_display_pos(x,y) and text_display_pos(x,y)
//         using scale_x and scale_y, pass them to this function
//         and create a transition to that spot, hold answer
//         for say, 5 or 10 seconds, return to usual spot
//         also modify the click path function to display
//         the answer in the display position (center of screen)
//         with the large text, until another column is clicked
//         also, aside, should clicking on non-selected path
//         disable current selected path? Currently, current
//         selection is turned off by clicking that path again
function installStartButtonHandlers(c, svg, startcol, 
                                    max_choices, rect_pos_y,
                                    data, results,
                                    scale_x, scale_y) {
    c.on("click", (event)=>{
        if( !isClickable(results, startcol, max_choices)) {
            return;
        }
        if(isMobile() 
            && canToggleHighlight(results,startcol)) {
            completedPathClicked(svg, startcol, results);
            return;
        } 
        c.attr("fill",getColor(startcol));
    
        var [col,path] = buildPath(svg, startcol, rect_pos_y, data);
        results.current[startcol].path = path;
        results.current[startcol].endcol = col;

        animateLine(svg, path, getColor(startcol), startcol, 
                    "selectedpathon",
                    "selectedpath",
                    d=>{
                        // remove and replace to make top of z-order
                        // remove
                        let rect2 = svg.select("#rectchoice"+col).remove();
                        let text2 = svg.select("#textchoice"+col).remove();
                        // re-add
                        svg.append("rect")
                            .attr("id","rectbg"+col)
                            .attr("x",scale_x(scale_x.domain()[1]/2)-1.5*rect2.attr("width"))
                            .attr("y",scale_y(scale_y.domain()[1]/2)-rect2.attr("height"))
                            .attr("class","selectedtextrect")
                            .attr("fill-opacity","0")
                            .attr("width",3*rect2.attr("width"))
                            .attr("height",2*rect2.attr("height"))
                        svg.append("text")
                            .text(text2.text())
                            .attr("id",text2.attr("id"))
                            .attr("class",text2.attr("class"))
                            .attr("x",text2.attr("x"))
                            .attr("y",text2.attr("y"))
                            .attr("dy",text2.attr("dy"))
                            .attr("font-family",text2.attr("font-family"))
                            .attr("font-size",text2.attr("font-size"))
                            .attr("text-anchor",text2.attr("text-anchor"));
                        svg.append("rect")
                            .attr("id",rect2.attr("id"))
                            .attr("class",rect2.attr("class"))
                            .attr("x",rect2.attr("x"))
                            .attr("y",rect2.attr("y"))
                            .attr("fill-opacity",rect2.attr("fill-opacity"))
                            .attr("width",rect2.attr("width"))
                            .attr("height",rect2.attr("height"));
                        // continue processing
                        var rect = svg.select("#rectchoice"+col);
                        var text = svg.select("#textchoice"+col);
                        var rectattr = {
                            x:parseInt(rect.attr("x")),
                            y:parseInt(rect.attr("y")),
                            w:parseInt(rect.attr("width")),
                            h:parseInt(rect.attr("height")),
                            x2:scale_x(scale_x.domain()[1]/2),
                            y2:scale_y(scale_y.domain()[1]/2)};
                        rectattr.x2 = rectattr.x2-rectattr.w/2;
                        rectattr.y2 = rectattr.y2-rectattr.h/2;
                        let rect3 = svg.select("#rectbg"+col);
                        rect3
                            .transition()        // fade-in
                            .ease(d3.easeLinear)
                            .attr("fill-opacity","1")
                            .duration(2600)
                            .transition()           
                            .delay(6500)         // hold
                            .ease(d3.easeLinear) // fade-out
                            .attr("fill-opacity","0")
                            .duration(500);
                        rect
                            .transition()        // move
                            .ease(d3.easeLinear)
                            .attr("x",rectattr.x2)
                            .attr("y",rectattr.y2)
                            .duration(1000)
                            .transition()        // expand
                            .ease(d3.easeLinear)
                            .attr("x",rectattr.x2-5)
                            .attr("y",rectattr.y2-5)
                            .attr("width",rectattr.w+10)
                            .attr("height",rectattr.h+10)
                            .attr("fill",getColor(startcol))
                            .duration(100)
                            .transition()        // contract
                            .ease(d3.easeLinear)
                            .attr("x",rectattr.x2)
                            .attr("y",rectattr.y2)
                            .attr("width",rectattr.w)
                            .attr("height",rectattr.h)
                            .duration(500)
                            .transition()        // fade
                            .ease(d3.easeLinear)
                            .duration(1000)
                            .attr("fill-opacity","0");
                        rect.classed("hideable",false);
                        let xt = text.attr("x");
                        let yt = text.attr("y");
                        text
                            .attr("stroke",getColor(startcol))
                            .attr("fill",getColor(startcol))
                            .transition()           // move
                            .ease(d3.easeLinear)
                            .duration(1000)
                            .attr("x",scale_x(scale_x.domain()[1]/2))
                            .attr("y",scale_y(scale_y.domain()[1]/2))                         
                            .transition()           
                            .delay(1600)            // wait
                            .ease(d3.easeLinear)    // expand
                            .duration(750)
                            .attr("font-size",8)
                            .transition()           // expand
                            .ease(d3.easeExpOut)
                            .duration(750)
                            .attr("font-size",font_Large())
                            .transition()           
                            .delay(5000)            // hold
                            .ease(d3.easeLinear)    // return
                            .duration(2000)
                            .attr("font-size",font_Normal())
                            .attr("x",xt)
                            .attr("y",yt)
                            .on("end", () => {
                                results.current[startcol].state = STATES.COMPLETED;
                            });
                    },
                    results);
    })
    .on("mouseenter", (event) => {
        if( isMobile() ) return;
        mouseEnterCircle(c, svg, results, startcol, max_choices);
    })
    .on("mouseleave", (enter) => {
        if( isMobile() ) return;
        mouseLeaveCircle(c, svg, results, startcol, max_choices);
    });
}

/*
===============================================================
// installPointHandlers(c, svg, scale_x, scale_y, n_points,
//                      data, results)
//   1) install handlers (mouseenter and mouseleave) for 
//      drawing valid dots to indicate where user is allowed 
//      to connect, 
//   2) install click handlers for when user clicks on valid
//      points. clicking either designates startign point
//      for connector, or draws connection and add information
//      to the global data structure 
===============================================================
*/
function installPointHandlers(c, svg, scale_x, scale_y, 
                                n_points,
                                data, results, c1) {
    c
        .on("mouseenter", (event)=>{
            if(resultCount(results) > 0) return;
            c.attr("r",1.5*c.attr("r"));

            var row = Math.round(scale_y.invert(c.attr("cy")));
            var col = Math.round(scale_x.invert(c.attr("cx")));
            if(c1.current == null && data.current[col][row].next == null) {
                c.attr("visibility","visible")
            } else if(data.current[col][row].next == null) {
                var row1 = Math.round(scale_y.invert(c1.current.attr("cy")));
                var col1 = Math.round(scale_x.invert(c1.current.attr("cx")));
                var diff = Math.abs(col-col1);
                if(diff<=1) {
                    var bounds = getBounds(col1, row1, n_points, data);
                    if((col<col1 
                        && row > bounds.left_lower
                        && row < bounds.left_upper) ||
                        (col>col1
                            && row > bounds.right_lower 
                            && row < bounds.right_upper) ||
                        (col === col1 && row !== row1)){
                        c.attr("visibility","visible");
                    }
                }
            }
        })
        .on("mouseleave", (event)=>{
            if(resultCount(results) > 0) return;
            c.attr("r",c.attr("r")/1.5);

            if(c1.current == null || 
                !(c1.current.attr("cx")===c.attr("cx") &&
                c1.current.attr("cy")===c.attr("cy"))) {
                    c.attr("visibility","hidden");
            }
        })
        .on( "click", (event)=>{
            if(resultCount(results) > 0
                || c.attr("visibility") === "hidden") return;

            var row = Math.round(scale_y.invert(c.attr("cy")));
            var col = Math.round(scale_x.invert(c.attr("cx"))); 
            if(c1.current == null && data.current[col][row].next == null) {
                c1.current = c;
            } else if(data.current[col][row].next == null) {
                var row1=Math.round(scale_y.invert(c1.current.attr("cy")));
                var col1=Math.round(scale_x.invert(c1.current.attr("cx")));
                var diff=Math.abs(col-col1); 
                if(diff===0) {
                    c1.current.attr("visibility","hidden");
                    if(row !== row1) {
                        c.attr("visibility","visible");
                        c1.current = c;
                    } else {
                        c1.current = null;
                    }
                } else if(diff === 1) {
                    svg.append("path")
                        .attr("class","connectpath")
                        .attr("d",getLinePath(c1.current,c))
                    c1.current.attr("visibility","hidden");
                    c1.current = null;
                    data.current[col1][row1].next = {col:col,
                                             row:row};
                    data.current[col][row].next = {col:col1,
                                             row:row1};
                }
            }
        });
}

/*
===============================================================
// randomArray(n, min, max)
//   returns an array of n unique values that fall between
//   min and max, inclusive 
===============================================================
*/
function randomArray(n, min, max) {
    var arr = [];
    while(arr.length < n){
        var r = min + Math.floor(Math.random() * (max-min));
        if(arr.indexOf(r) === -1) arr.push(r);
    }
    return arr;
}

/*
===============================================================
// handleResetClick(svg, choices, scale_x, scale_y, 
//                  n_points, data, results)
//   1) removes all relevant class attributes and clears global
//        data and global results
//   2) creates set of random connections between vertical lines
//        based on settings specs and populates global data
//        (ressults stays empty until start button clicked)
//   
===============================================================
*/
function handleResetClick(svg, choices, scale_x, scale_y, 
                            n_points, data, results) {
    svg.selectAll(".selectedpath, .connectpath").remove();
    svg.selectAll(".textchoice").attr("font-size",4);
    svg.selectAll(".rectchoice").attr("fill-opacity",1);
    data.current = Array.from({length:choices.length},
                        (x,i)=>Array.from({length:n_points}, 
                            (x,j)=>{
                                return {
                                    x:scale_x(i),
                                    y:scale_y(j),
                                    next:null};}));
    for(var col=0; col<data.current.length; ++col) {
        for(var row=0; row<data.current[col].length; ++row) {
        data.current[col][row].next = null;
        }
    }
    for(col=0; col<results.current.length; ++col) {
        results.current[col] = {path:null,
                                state:STATES.UNEXPLORED,
                                endcol:null};
    }
    var rndvals = Array.from(
        {length:(choices.length-1)},x=>null);
    
    for(var i=0; i<rndvals.length; ++i) {
        var arr = randomArray(getRandomInt(),0,n_points);
        col = i;
        var col2 = col+1;
        for(var k=0; k<arr.length; ++k) {
            row = arr[k];
            while(data.current[col][row].next !== null) {
                ++row;
                if(row === data.current[col].length) break;
            }
            if(row === data.current[col].length) continue;

            data.current[col][row].next={col:col2,row:row};
            data.current[col2][row].next={col:col,row:row};
            var path2 = "M" + data.current[col][row].x
            + " " + MARGIN.TOP
            + " L" + data.current[col][row].x
            + " " + data.current[col][row].y
            + " L" + data.current[col2][row].x
            + " " + data.current[col2][row].y;
            svg.append("path")
                .attr("class","connectpath")
                .attr("d",path2)
        }
    }
}


/*
===============================================================
// createScales(choices)
//   choices = array of object, {name,count}
//   uses the current window's inner width and height to 
//   determine the appropariate size of the svg. 
//   
//   side effects:
//   svg.settings.width, svg.settings.height
//
//   returns:
//   x_scale, y_scale, rect_pos_y, rect_width, rect_height,
//   svg_width, svg_height, n_points
===============================================================
*/
function createScales(choices) {
    let svg_height = Math.floor(window.innerHeight*.9);
    let svg_width = Math.floor(window.innerWidth*.9);
    let svg_ratio = svg_width/svg_height;
    let max_width = svg_width - MARGIN.LEFT - MARGIN.RIGHT;
    const n = choices.length;
    const point_radius = 5;
    const point_bottom_margin = point_radius*4;
    const point_top_margin = point_radius*4;
    const x_sep = 20;
    const rect_width = BUTTON_RADIUS*4;
    const rect_height = BUTTON_RADIUS*2;

    let item_width = Math.max(  rect_width,
                                2*BUTTON_RADIUS );
    let range_width = (item_width+x_sep)*n - x_sep;

    if(range_width > max_width) {
        // adjust height to keep same aspect ratio
        // think I can use svg.preserve ratio somehow,
        // but easy enough to do manually for now
        let add_width = range_width-max_width;
        svg_width += add_width;
        svg_height = svg_width/svg_ratio;
    }
    let n_points = Math.floor((svg_height 
                            - MARGIN.TOP - MARGIN.BOTTOM)
                            / (2*point_radius));
    let rect_pos_y = (svg_height-MARGIN.BOTTOM-rect_height);
    let range_height = svg_height 
                        - MARGIN.TOP
                        - MARGIN.BOTTOM
                        - point_top_margin
                        - point_bottom_margin
                        - rect_height;
    let range_top = MARGIN.TOP + point_top_margin;
    let range_bot = range_top + range_height;
    let range_left = MARGIN.LEFT 
                    + ( (max_width > range_width) ?
                        (max_width - range_width)/2 :
                        0);
    let range_right = range_left + range_width;

    let scale_x = d3.scaleLinear()
                    .domain([0,choices.length-1])
                    .range([range_left,range_right]);
    let scale_y = d3.scaleLinear()
                    .domain([0,n_points-1])
                    .range([range_top,range_bot]);

    return [scale_x, scale_y,
            rect_pos_y, 
            rect_width, rect_height,
            svg_width, svg_height, 
            n_points];
}

/*
===============================================================
// SubwaySelector(choices,max_choices)
//   choices = array of object, {name,count}
//   max_choices = number of times user can play. if null,
//                 no limit on number of plays
//   1) set up scale functions, initialize data and
//        results refs
//   2) draw start buttons and install handlers
//   3) draw vertical lines
//   4) draw text boxes at bottom with text hidden
//   5) add all click points (hidden) to each vertical line
//        and add x,y position to global data structure
//   6) call handleResetClick to initialize random connections 
===============================================================
*/
const SubwaySelector = ({   flag_reset,
                            setFlagReset, 
                            choices=DEFAULT_CHOICES,
                            max_choices=1   }) => {
    const ref = useRef();
    if(choices.length === 0) {
        choices = DEFAULT_CHOICES;
    }
    var [resetSubway, setResetSubway] = useState(true);
    var data = useRef([]);
    var results = useRef(Array.from(
                        {length:choices.length},
                        (x,i)=>null));
    var clickPnt1 = useRef(null);

    useEffect(() => {
        let svg_height = Math.floor(window.innerHeight*.8);
        let svg_width = Math.floor(window.innerWidth);
        const svg = d3.select(ref.current)
                    .attr("class","svg")
                    .attr("height",svg_height)
                    .attr("width",svg_width);
    
        d3.selectAll(".inputtext").remove();
        d3.selectAll(".setupline").remove();
        shuffleArray(choices);       

        let scale_x = ()=>{};
        let scale_y = ()=>{};
        let rect_pos_y=0;
        let rect_width=0;
        let rect_height=0;
        let n_points=0;
        [ scale_x, scale_y,
            rect_pos_y,
            rect_width, rect_height,
            svg_width, svg_height,
            n_points] = createScales(choices);
        svg.attr("viewBox","0 0 " + svg_width + " " + svg_height);

        // note: if selectAll.data.enter pattern used, then
        // handlers are called for all paths and all points
        // I can't quite figure out why.
        // Looping and appending one by one, appears to create
        // a separate handler for each item.
        for(var i=0; i<data.current.length; ++i) {
            var c = svg.append("circle");
            let cur_choice = choices[i].name;
            c.attr("cx",scale_x(i)).attr("cy",MARGIN.TOP/2)
                .classed("setupline",true)
                .classed("selectbutton",true)
                .attr("r",BUTTON_RADIUS)
                .call(installStartButtonHandlers, svg, i, max_choices, rect_pos_y, 
                                                    data, results,
                                                    scale_x,scale_y);
            c.attr("fill",getColor(i))
            svg.append("path")
                .attr("d","M"+scale_x(i)+" "+MARGIN.TOP
                        + " L"+scale_x(i)+" "+rect_pos_y)
                .classed("setupline",true)
                .classed("verticalpath",true);
            svg.append("text")
                .text(d=>cur_choice)
                .attr("id","textchoice"+i)
                .attr("x",scale_x(i))
                .attr("y",rect_pos_y+(rect_height/2))
                .attr("dy",".35em")
                .attr("font-family","sans-serif")
                .attr("font-size",4)
                .attr("text-anchor","middle")
                .classed("textchoice",true)
                .classed("setupline",true)
                .classed("hideable",true);
            svg.append("rect")
                .attr("id","rectchoice"+i)
                .attr("x",scale_x(i)-rect_width/2)
                .attr("y",rect_pos_y)
                .attr("fill-opacity",1)
                .attr("width",rect_width).attr("height",rect_height)
                .classed("rectchoice",true)
                .classed("setupline",true)
                .classed("hideable",true);
        }

        if(resetSubway || flag_reset) {
            setResetSubway(false);
            setFlagReset(false);
            handleResetClick(svg, choices, scale_x, scale_y, n_points,
                data, results);
        }
        refreshClickPoints(svg, scale_x, scale_y, n_points,
            data, results, clickPnt1);
        
    },[flag_reset, setFlagReset, 
        choices, max_choices, 
        resetSubway, setResetSubway]);

    return (
        <svg ref={ref} />
    )
}

export default SubwaySelector;