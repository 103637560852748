import { Chip } from "@mui/material";
//import { Card, Typography } from "@mui/material";

const Prize = ({prize,handleDelete}) => {
    return (
        <Chip label={prize.name} variant="outlined" color="primary" onDelete={() => handleDelete(prize)} />
    );
}

//<Card
//style={{
//    padding: 12,
//    margin: 4,
//}}
//>
//<Typography variant="h6">
//{prize.name} {/*({prize.count})*/}
//</Typography>
//</Card>
export default Prize;