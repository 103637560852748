import SubwaySelector from "../components/SubwaySelector";

const Prizes = () => {
    return (
        <div>
            <SubwaySelector choices={["Prize1","Prize2","Prize3","Prize4","Prize5"]}/>
        </div>
    );
}

export default Prizes;