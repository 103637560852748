import { Button } from "@mui/material";
import logo from "../logo.svg"
import { Link } from "react-router-dom";

const AppHeader = ({setFlagNew,setFlagReset,flag_about}) => {
    return (
            <div className="container">
                <div className="centered-div" style={{ cursor: 'pointer'}}>
                    <img src={logo} className="App-logo" alt="logo" />
                    <br/>
                    <code>Kettei</code>
                </div>
                <div hidden={flag_about} aclassName="right-aligned-div">
                    <Button onClick={() => {
                        setFlagReset(true);
                    }}>Reset</Button>
                    <Button onClick={() => {
                        setFlagNew(true);
                    }} LinkComponent={Link} to="/">
                        New</Button>
                </div>
            </div>
    );
}
/*
        <header className="App-header">
    </header>
*/
/* <p style={{ cursor: 'pointer'}}>
<img src={logo} className="App-logos" alt="logo" />
<code>Kettei</code>
</ p> */

export default AppHeader;