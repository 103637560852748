import {Route, Routes} from 'react-router-dom';
import About from './About';
import Lottery from "./Lottery";
import Teams from "./Teams";
import Prizes from "./Prizes";
import Magic from "./Magic";
import PrizesSetup from './PrizesSetup';
import Subway from "./Subway";
import { useState } from 'react';

const Router = ({   flag_reset,
                    setFlagReset,
                    flag_new,
                    setFlagNew,
                    setFlagAbout }) => {
    const [prizes,setPrizes] = useState([]);
    const [max_prizes,setMaxPrizes] = useState(2);

    return (
        <Routes>
            <Route path='/' element={<About 
                prizes={prizes}
                setPrizes={setPrizes}
                maxPrizes={max_prizes}
                setMaxPrizes={setMaxPrizes}
                flag_new={flag_new}
                setFlagNew={setFlagNew}
                setFlagAbout={setFlagAbout}/>} />
            <Route path='/about' element={<About />} />
            <Route path='/lottery' element={<Lottery />} />
            <Route path='/teams' element={<Teams />} />
            <Route path='/prizes' element={<Prizes />} />
            <Route path='/magic' element={<Magic />} />
            <Route path='/demo' element={<PrizesSetup />} />
            <Route path='/subway' element={<Subway 
                                            flag_reset={flag_reset}
                                            setFlagReset={setFlagReset}
                                            choices={prizes}
                                            max_choices={max_prizes}
                                            setFlagAbout={setFlagAbout}/>} />
            <Route
                path="*"
                element={<h2>Page not found</h2>}
            />
        </Routes>
    );
};

export default Router;