import SubwaySelector from "../components/SubwaySelector";

const Teams = () => {
    return (
        <div>
            <SubwaySelector choices={["Team1","Team2","Team2","Team2","Team1","Team1","Team2","Team1"]}/>
        </div>
    );
}

export default Teams;